var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: "dashboard-component dashboard-theme-" + _vm.theme },
    [
      _c(
        "a-layout",
        [
          _c(
            "a-layout",
            [
              _c(
                "a-layout-header",
                { staticClass: "dashboard-header" },
                [
                  _c(
                    "a-row",
                    [
                      _c("a-col", { attrs: { span: 10 } }, [
                        _c(
                          "div",
                          { staticClass: "header-logo" },
                          [
                            _c("img", {
                              staticStyle: {
                                height: "24px",
                                margin: "-10px 8px 0 0",
                              },
                              attrs: { src: _vm.Logo },
                            }),
                            _c("a-divider", { attrs: { type: "vertical" } }),
                            _c("span", { staticClass: "header-title" }, [
                              _vm._v(_vm._s(_vm.CorpName)),
                            ]),
                            !_vm.IsSuperAdmin && _vm.CurrentSelectProjectName
                              ? _c(
                                  "span",
                                  { staticClass: "header-current-project" },
                                  [
                                    _vm._v(
                                      " (" +
                                        _vm._s(_vm.CurrentSelectProjectName) +
                                        ") "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      _c("a-col", { attrs: { span: 14 } }, [
                        _c(
                          "div",
                          { staticClass: "username-menu text-right" },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "header-current-version",
                                staticStyle: { "margin-left": "16px" },
                              },
                              [_vm._v("系统版本: " + _vm._s(_vm.Version))]
                            ),
                            _c(
                              "a-dropdown",
                              {
                                staticClass: "header-theme",
                                attrs: {
                                  trigger: ["click"],
                                  "overlay-class-name": "header-theme-overlay",
                                },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "ant-dropdown-link drop-menu-text text-ellipsis",
                                    on: {
                                      click: function (e) {
                                        return e.preventDefault()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(" 颜色主题 "),
                                    _c("a-icon", { attrs: { type: "down" } }),
                                  ],
                                  1
                                ),
                                _c(
                                  "a-menu",
                                  {
                                    attrs: { slot: "overlay" },
                                    slot: "overlay",
                                  },
                                  [
                                    _c(
                                      "a-menu-item",
                                      {
                                        key: "2393cd",
                                        on: {
                                          click: function ($event) {
                                            return _vm.onThemeChange("#2393cd")
                                          },
                                        },
                                      },
                                      [
                                        _c("span", {
                                          staticClass:
                                            "theme-item theme-item-blue",
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "a-menu-item",
                                      {
                                        key: "red",
                                        on: {
                                          click: function ($event) {
                                            return _vm.onThemeChange("red")
                                          },
                                        },
                                      },
                                      [
                                        _c("span", {
                                          staticClass:
                                            "theme-item theme-item-red",
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "a-menu-item",
                                      {
                                        key: "green",
                                        on: {
                                          click: function ($event) {
                                            return _vm.onThemeChange("green")
                                          },
                                        },
                                      },
                                      [
                                        _c("span", {
                                          staticClass:
                                            "theme-item theme-item-green",
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "a-menu-item",
                                      {
                                        key: "a818cc",
                                        on: {
                                          click: function ($event) {
                                            return _vm.onThemeChange("#a818cc")
                                          },
                                        },
                                      },
                                      [
                                        _c("span", {
                                          staticClass:
                                            "theme-item theme-item-purple",
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "a-menu-item",
                                      {
                                        key: "orange",
                                        on: {
                                          click: function ($event) {
                                            return _vm.onThemeChange("orange")
                                          },
                                        },
                                      },
                                      [
                                        _c("span", {
                                          staticClass:
                                            "theme-item theme-item-orange",
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            !_vm.IsSuperAdmin
                              ? _c(
                                  "span",
                                  { staticClass: "header-project-wrapper" },
                                  [
                                    _c(
                                      "a-dropdown",
                                      { attrs: { trigger: ["click"] } },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "ant-dropdown-link drop-menu-text text-ellipsis",
                                            on: {
                                              click: function (e) {
                                                return e.preventDefault()
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.CurrentSelectProjectName ||
                                                    "请选择项目"
                                                ) +
                                                " "
                                            ),
                                            _c("a-icon", {
                                              attrs: { type: "down" },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm.projectList &&
                                        _vm.projectList.length > 0
                                          ? [
                                              _c(
                                                "a-menu",
                                                {
                                                  attrs: { slot: "overlay" },
                                                  slot: "overlay",
                                                },
                                                _vm._l(
                                                  _vm.projectList,
                                                  function (item) {
                                                    return _c(
                                                      "a-menu-item",
                                                      {
                                                        key: item.id,
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.selectProjectClick(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(item.name) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ]
                                          : [
                                              _c("a-empty", {
                                                staticClass: "text-center",
                                                staticStyle: {
                                                  padding: "16px 32px",
                                                },
                                                attrs: {
                                                  slot: "overlay",
                                                  image: _vm.EmptySimpleImage,
                                                  description: "暂无项目",
                                                },
                                                slot: "overlay",
                                              }),
                                            ],
                                      ],
                                      2
                                    ),
                                    !_vm.IsCorpAdmin
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "backto-corp-a",
                                            on: { click: _vm.backToCorpClick },
                                          },
                                          [_vm._v("返回企业")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c("jtl-icon", {
                              staticClass: "user-header",
                              attrs: { type: "person" },
                            }),
                            _c(
                              "a-dropdown",
                              { attrs: { trigger: ["click"] } },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "ant-dropdown-link drop-menu-text text-ellipsis",
                                    attrs: { title: _vm.UserName },
                                    on: {
                                      click: function (e) {
                                        return e.preventDefault()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(" " + _vm._s(_vm.UserName) + " "),
                                    _c("a-icon", { attrs: { type: "down" } }),
                                  ],
                                  1
                                ),
                                _c(
                                  "a-menu",
                                  {
                                    attrs: { slot: "overlay" },
                                    slot: "overlay",
                                  },
                                  [
                                    _c(
                                      "a-menu-item",
                                      { key: "0" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: "/dashboard/person-center",
                                            },
                                          },
                                          [_vm._v("个人中心")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("a-menu-item", { key: "1" }, [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            target: "_blank",
                                            href: _vm.OpsUrl,
                                          },
                                        },
                                        [_vm._v("运维平台")]
                                      ),
                                    ]),
                                    _c("a-menu-divider"),
                                    _c(
                                      "a-menu-item",
                                      {
                                        key: "2",
                                        on: { click: _vm.loginOutClick },
                                      },
                                      [_vm._v("退出")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-layout",
                { staticClass: "dashboard-content" },
                [
                  _c(
                    "a-layout-sider",
                    {
                      staticClass: "dashboard-sider",
                      attrs: { trigger: null, collapsible: "" },
                      model: {
                        value: _vm.collapsed,
                        callback: function ($$v) {
                          _vm.collapsed = $$v
                        },
                        expression: "collapsed",
                      },
                    },
                    [
                      _c(
                        "a-menu",
                        {
                          attrs: {
                            theme: _vm.theme,
                            mode: "inline",
                            "default-open-keys": _vm.DefaultOpenMenuKey,
                          },
                          on: {
                            "update:defaultOpenKeys": function ($event) {
                              _vm.DefaultOpenMenuKey = $event
                            },
                            "update:default-open-keys": function ($event) {
                              _vm.DefaultOpenMenuKey = $event
                            },
                          },
                        },
                        [
                          _vm._l(_vm.MenuList, function (menu, index) {
                            return [
                              menu.subMenus && menu.subMenus.length > 0
                                ? _c(
                                    "a-sub-menu",
                                    {
                                      key: menu.route,
                                      class: [
                                        "ant-sub-menu-item-wrapper",
                                        {
                                          "ant-sub-menu-item-wrapper__selected":
                                            _vm.isMenuSelected(menu.route),
                                        },
                                      ],
                                      on: {
                                        titleClick: function ($event) {
                                          _vm.OpenMenuKeys = menu.route
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "title" },
                                          slot: "title",
                                        },
                                        [
                                          _c("jtl-icon", {
                                            attrs: { type: menu.icon },
                                          }),
                                          _c("span", [
                                            _vm._v(_vm._s(menu.name)),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._l(menu.subMenus, function (subMenu) {
                                        return _c(
                                          "a-menu-item",
                                          {
                                            key: subMenu.route,
                                            class: [
                                              {
                                                "ant-menu-sub-item-selected":
                                                  _vm.isMenuSelected(
                                                    subMenu.route
                                                  ),
                                              },
                                            ],
                                            on: {
                                              click: function ($event) {
                                                return _vm.menuClick(subMenu)
                                              },
                                            },
                                          },
                                          [
                                            subMenu.route ===
                                            "/dashboard/workorder/ToClaim/All"
                                              ? _c(
                                                  "a-badge",
                                                  {
                                                    attrs: {
                                                      count: _vm.toClaimNumber,
                                                      offset: [15, 0],
                                                      "show-zero": true,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(subMenu.name) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : subMenu.route ===
                                                "/dashboard/workorder/AssignedTo/All"
                                              ? _c(
                                                  "a-badge",
                                                  {
                                                    attrs: {
                                                      count: _vm.assignedNumber,
                                                      offset: [15, 0],
                                                      "show-zero": true,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(subMenu.name) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _c("span", [
                                                  _vm._v(_vm._s(subMenu.name)),
                                                ]),
                                          ],
                                          1
                                        )
                                      }),
                                    ],
                                    2
                                  )
                                : _c(
                                    "a-menu-item",
                                    {
                                      key: menu.route,
                                      class: [
                                        "ant-menu-item-wrapper",
                                        {
                                          "ant-menu-item-selected ant-menu-item-wrapper__selected":
                                            _vm.isMenuSelected(menu.route),
                                        },
                                      ],
                                      on: {
                                        click: function ($event) {
                                          return _vm.menuClick(menu)
                                        },
                                      },
                                    },
                                    [
                                      _c("jtl-icon", {
                                        attrs: { type: menu.icon },
                                      }),
                                      _c("span", [_vm._v(_vm._s(menu.name))]),
                                    ],
                                    1
                                  ),
                              _c("a-menu-divider", { key: index }),
                            ]
                          }),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "collapsed-wrapper text-center",
                          on: { click: _vm.menuCollapseClick },
                        },
                        [_c("a-icon", { attrs: { type: _vm.CollapsedIcon } })],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-layout-content",
                    { staticClass: "dashboard-view" },
                    [_c("router-view", { staticClass: "dashboard-content" })],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }